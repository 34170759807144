import {useEffect, useState} from "react";
import axios from "axios";
import {CircleLoader} from "react-spinners"

const Deposit_withdraw = (props)=>{

    const [filter,setFilter] = useState(false);
    const [filter_b_visible,set_filter_b_visible] = useState(false);
    const [filter_c_visible,set_filter_c_visible] = useState(false);

    const [filter_b_value,set_filter_b_value] = useState("solana");
    const [filter_c_value,set_filter_c_value] = useState("CHAMMBS");
    const [all_blocks,set_all_blocks] = useState([{}]);
    const [all_blocks_loading,set_all_blocks_loading] = useState(false);
    const [all_assets,set_all_assets] = useState([]);
    const [all_assets_loading,set_all_assets_loading] = useState(false);

    const [ast,setAst] = useState(false);
    const [bc,setBc] = useState(true);

    const [page_offset,set_page_offset] = useState(0);
    const [page_limit,set_page_limit] = useState(50);

    
        
        const fetchAll = async()=>{
            set_all_blocks_loading(true);
            await axios.get(`https://chambsexchange.onrender.com/api/balance/all-users-wallet?page=${page_offset}&limit=${page_limit}`).then((response)=>{
                console.log("Correeecccctt:" ,response.data.walletDetails);
                set_all_blocks(response.data.walletDetails);
                set_all_blocks_loading(false);
                
            }).catch(async(err)=>{
                console.log("erroorrr:  ",err);
                set_all_blocks_loading(false);
                await fetchAll();
            })
        }
    useEffect(()=>{
        fetchAll();
    },[]);

    const fetchAssets = async()=>{
        set_all_assets_loading(true);
        await axios.get("https://chambsexchange.onrender.com/api/address/currencies/binance").then((response)=>{
            console.log("Correeecccctt:" ,response.data);
            set_all_assets(Object.values(response.data));
            set_all_assets_loading(false);
        }).catch((err)=>{
            console.log("erroorrr:  ",err);
            set_all_assets_loading(false);
        })
    }

    


    return (
        <div style={{width:props.width,height:props.height,opacity:props.opacity,transition:"width 0.2s linear, height 0.2s linear",marginLeft:"20px",marginRigt:"20px",overflow:"auto",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"start"}}>
            <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"start"}}>
                 <h1 style={{width:"40%"}}>WALLET</h1>
                 <div style={{width:"40%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>
                    {/* Filter */}
                    <div>
                        <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",backgroundColor:"rgb(47,47,47)",color:"white",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}
                        onClick={()=>{
                            setFilter(!filter)
                        }}>Filter</div>
                        <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",color:"white",display: filter == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}>
                        <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",boxShadow:"10px 10px 10px rgb(47,47,47)",color:"white",display: filter == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}>
                            <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",backgroundColor:"rgb(47,47,47)",color:"white",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}
                            onClick={async()=>{
                                set_filter_c_visible(true);
                                set_filter_b_visible(false);
                                await fetchAssets();
                            }}
                            >By Asset ({filter_c_value})</div>
                            {
                                all_assets.map((item)=>(
                                    <>
                                    {
                                        all_assets_loading == true?
                                        (
                                            <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",boxShadow:"10px 10px 10px rgb(47,47,47)",color:"black",display: filter_c_visible == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}><CircleLoader/></div>
                                        ):
                                        (
                                            <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",boxShadow:"10px 10px 10px rgb(47,47,47)",color:"black",display: filter_c_visible == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}
                                            onClick={()=>{
                                                set_filter_c_value(item);
                                                setAst(true);
                                                setBc(false);
                                            }}
                                            >{item}</div>
                                        )
                                    }
                                    </>
                                    
                                ))
                            }
                        </div>

                        <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",boxShadow:"10px 10px 10px rgb(47,47,47)",color:"white",display: filter == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}>
                            <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",backgroundColor:"rgb(47,47,47)",color:"white",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}
                            onClick={()=>{
                                    set_filter_c_visible(false);
                                    set_filter_b_visible(true)
                            
                            }}
                            >By Blockchain ({filter_b_value})</div>
                            {
                                ["BINANCE","SOLANA","TON"].map((item,index)=>(
                                    <div style={{paddingLeft:"50px",paddingRight:"50px",cursor:"pointer",paddingTop:"10px",paddingBottom:"10px",borderRadius:"50px",boxShadow:"10px 10px 10px rgb(47,47,47)",color:"black",display: filter_b_visible == false? "none": "flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontWeight:"bold"}}
                                    onClick={()=>{
                                        setAst(false);
                                        setBc(true);
                                        if(index == 0){
                                            set_filter_b_value("binance");
                                        }else if(index == 1){
                                            set_filter_b_value("solana");
                                        }else if(index == 2){
                                            set_filter_b_value("ton");
                                        }
                                    }}
                                    >{item}</div>
                                ))
                            }
                        </div>
                            
                            
                        </div>
                        {/* <div>ASSET</div>
                        <div>BLOCKCHAIN</div> */}
                    </div>
                </div>
            </div>
            {/* Pagination */}
           <div style={{width:"100%",display:"flex",fontWeight:"bold",marginBottom:"10px",flexDirection:"row",alignItems:"center",justifyContent:"start"}}>
            <div style={{cursor:"pointer"}} onClick={async()=>{
                set_page_limit(l=> l -= 50);
                set_page_offset(p=> p -= 50);
                await fetchAll();
            }}>Back</div>
            <div  style={{marginLeft:"30px",cursor:"pointer"}}
            onClick={async()=>{
                set_page_limit(l=> l += 50);
                set_page_offset(p=> p += 50);
                await fetchAll();
            }}
            >Forward ({page_offset}-{page_limit})</div>
           </div>

            <table style={{width:"90%"}}>
                <thead style={{backgroundColor:"rgb(47,47,47)"}}>
                    <tr style={{color:"white"}}>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>BLOCKCHAIN</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>CURRENCY</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>BALANCE</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>ADDRESS</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>USER ID</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>PRIVATE KEY</strong></td>
                        <td style={{paddingTop:"10px",paddingBottom:"10px"}}><strong>ACTION</strong></td>
                    </tr>
                </thead>
                <tbody>
                    
                    {
                        all_blocks_loading == false?

                        all_blocks.map((item,index)=>(
                            <>{
                               (item.blockchain ==  filter_b_value && bc == true && Number(item.bbalance) > 0) || (item.currency == filter_c_value && ast == true && Number(item.bbalance) > 0)?
                                (
                                <tr style={{color:"black",marginBottom:"20px"}}>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.blockchain}</strong></td>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.currency}</strong></td>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.bbalance}</strong></td>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.address}</strong></td>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.userId}</strong></td>
                                    <td style={{color:"black",borderRight:"3px solid rgb(160,160,160)",borderBottom:"2px solid rgb(160,160,160)"}}><strong>{item.privateKey}</strong></td>
                                    <td style={{color:"black"}}><strong style={{marginLeft:"10px",paddingLeft:"20px",paddingRight:"20px",paddingTop:"10px",paddingBottom:"10px",borderRadius:"10px",backgroundColor:"rgb(20,200,20)",color:"white",display:"flex",cursor:"pointer",alignItems:"center",justifyContent:"center"}}>Approve</strong></td>
                                </tr>
                                ): null
                            }
                            </>
                        
                        )):
                        <tr style={{color:"black"}}>
                                    <td><strong><CircleLoader/></strong></td>
                                    <td><strong><CircleLoader/></strong></td>
                                    <td><strong><CircleLoader/></strong></td>
                                    <td><strong><CircleLoader/></strong></td>
                                    <td><strong><CircleLoader/></strong></td>
                                    <td><strong><CircleLoader/></strong></td>
                                </tr>
                        
                    }
                    
                </tbody>
            </table>
        </div>
    );
}

export default Deposit_withdraw;