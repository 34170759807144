import Top_bar from "./nav_bars/top_bar";
import Side_bar from "./nav_bars/side_bar";
import Deposit_withdraw from "./screens/deposit_withdraw";
import {useState, useEffect} from "react";
import Dashboard from "./screens/dashboard";
import {useNavigate} from "react-router-dom";
import Trading from "./screens/trading";

const Main_screen = ()=>{

    const [wall,setWall] = useState(false);
    const [dash,setDash] = useState(true);
    const [trad,setTrad] = useState(false);

    const [drawer1,setDrawer1] = useState(true);
    const navigate = useNavigate();

    const [wall_width,set_wall_width] = useState("0%");
    const [wall_height,set_wall_height] = useState("0%");

    const [dash_width,set_dash_width] = useState("100%");
    const [dash_height,set_dash_height] = useState("100%");

    const [trad_width,set_trad_width] = useState("0%");
    const [trad_height,set_trad_height] = useState("0%");

    const userToken = localStorage.getItem("userToken");

    useEffect(()=>{
        if(!userToken){
            navigate("/login");
        }
    },[])

    useEffect(()=>{
        const storedTime = localStorage.getItem("startTime");
        const currentTime = new Date().getTime();
      
        if(storedTime){
          if(24 * 60 * 60 * 1000 - (currentTime - parseInt(storedTime, 10)) <= 0){
                localStorage.removeItem("userToken");
                localStorage.removeItem("startTime");
                navigate("/login");
          }else{
            const timer = setTimeout(()=>{
                localStorage.removeItem("userToken");
                localStorage.removeItem("startTime");
                navigate("/login");
            },24 * 60 * 60 * 1000 - (currentTime - parseInt(storedTime, 10)));
      
            return clearTimeout(timer);
          }
        }else{
          navigate("/login");
        }
        },[]);

    return(
        <div style={{width:"100%",height:"100%",position:"absolute",backgroundColor:"white"}}>
            {/* top bar */}
            <div style={{width:"100%",height:"10%",top:"0px",left:"0px",borderBottom: "2px solid rgb(18,22,28)"}}>
                <Top_bar/>
            </div>
            
            <div style={{width:"100%",height:"90%",overflow:"auto",backgroundColor:"white",display:"flex",flexDirection:"row",alignItems:"start",justifyContent:"start"}}>
                {/* side bar */}
                <div style={{width:drawer1 == true? "10%": "0%",height:"100%",transition:"width 0.5s linear",backgroundColor:"rgb(18,22,28)"}}>
                    <Side_bar wall={()=>{
                        setWall(true);
                        setDash(false);
                        setTrad(false);
                        set_trad_height("0%");
                        set_trad_width("0%");
                        set_wall_height("100%");
                        set_wall_width("100%");
                        set_dash_width("0%");
                        set_dash_height("0%");
                    }} dash={()=>{
                        setWall(false);
                        setDash(true);
                        setTrad(false);
                        set_trad_height("0%");
                        set_trad_width("0%");
                        set_wall_height("0%");
                        set_wall_width("0%");
                        set_dash_width("100%");
                        set_dash_height("100%");
                    }} trad={()=>{
                        setWall(false);
                        setDash(false);
                        setTrad(true);
                        set_trad_height("100%");
                        set_trad_width("100%");
                        set_wall_height("0%");
                        set_wall_width("0%");
                        set_dash_width("0%");
                        set_dash_height("0%");
                    }}
                    
                    drawer1={()=>{
                        setDrawer1(!drawer1);
                    }}
                    
                    />
                </div>

                {/* body */}
                <div style={{width:"90%",height:"100%",overflow:"auto",backgroundColor:"transparent"}}>
                    {/* {
                        wall == true?
                            (<Deposit_withdraw width={wall_width} height={wall_height}/>):null 
                    }
                    {
                        dash == true?
                            (<Dashboard width={dash_width} height={dash_height}/>)
                        :null    
                    } */}

                    <Dashboard opacity={dash_width == "0%" && dash_height== "0%"? "0": "0.9"} width={dash_width} height={dash_height}/>
                    <Deposit_withdraw opacity={wall_width == "0%" && wall_height== "0%"? "0": "0.9"} width={wall_width} height={wall_height}/>
                    <Trading  opacity={trad_width == "0%" && trad_height== "0%"? "0": "0.9"} width={trad_width} height={trad_height}/>
                   
                </div>

            </div>
            
        </div>
    );
}

export default Main_screen;