import {useEffect, useState} from "react";
import { FaChevronCircleDown,FaChevronDown } from "react-icons/fa";
import {CircleLoader} from "react-spinners"
import axios from "axios";

const Trading = (props)=>{

    const [bot,setBot] = useState(true);
    const [tradeType,set_tradeType] = useState("buy");
    const [base_price,set_base_price] = useState();
    const [price,set_price] = useState();
    const [numb_trad,set_numb_trad] = useState();
    const [quan,set_quan] = useState();
    // const [pair,setPair] = useState("");

    const [executing,setExecuting] = useState(false);
    const [all_assets,set_all_asssets] = useState([]);
    const [asset,setAsset] = useState("");
    const [mess,setMess] = useState("");

    // https://chambsexchange.onrender.com/api/address/all-unique-curerency


    useEffect(()=>{

       const getAssets = async()=>{
        await axios.get("https://chambsexchange.onrender.com/api/address/all-unique-curerency")
        .then((response)=>{
            console.log("res:",response.data);
            set_all_asssets(response.data);
        }).catch(async(err)=>{
            console.log("An err: ",err);
            await getAssets();
        });
        }
        
        getAssets();
    },[]);
    

    const executeTrade = async()=>{
        setExecuting(true);
        setMess("");
        await axios.post("https://chambsexchange.onrender.com/api/spot/trade-bot",{
            asset: asset,
            orderType: tradeType,
            limitPrice: Number(price),
            orderCount: Number(numb_trad),
            basePrice: Number(base_price),
            quantity: Number(quan)
        })
        .then((response)=>{
            setExecuting(false);
            console.log("res:",response.data);
            setMess("Success");
        }).catch((err)=>{
            setExecuting(false);
            console.log("An err: ",err);
            setMess("Execution failed");
        });
    }

    return (
        <div style={{width:props.width,height:props.height,display:"flex",opacity:props.opacity,flexDirection:"column",alignItems:"center",justifyContent:"start",marginLeft:"20px",transition:"width 0.2s linear, height 0.2s linear"}}>

            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly"}}>
                <div style={{boxShadow: bot == true? "10px 10px 10px black": "0px 0px 0px transparent",opacity:bot==true? "0.9": "0.3",fontWeight:"bold",width:"30%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",paddingTop:"20px",paddingBottom:"20px",cursor:"pointer",backgroundColor:"rgb(18,22,28)",color:"white"}} 
                onClick={()=>{
                    setBot(true);
                }}
                >TradingBot</div>
                <div style={{boxShadow: bot == false? "10px 10px 10px black": "0px 0px 0px transparent",opacity:bot==false? "0.9": "0.3",fontWeight:"bold",width:"30%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",paddingTop:"20px",paddingBottom:"20px",cursor:"pointer",backgroundColor:"rgb(18,22,28)",color:"white"}} 
                onClick={()=>{
                    setBot(false)
                }}
                >Order list</div>
            </div>

            {
                bot == true?
                (
                    <div style={{width:"80%",marginTop:"20px",paddingTop:"20px",paddingBottom:"20px",backgroundColor:"rgb(18,22,28)",color:"white",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>

                        <div style={{width:"80%",marginTop:"20px",paddingTop:"20px",paddingBottom:"20px",backgroundColor:"rgb(18,22,28)",color:"white",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                        <div style={{boxShadow: "10px 10px 10px black",borderRadius:"20px",fontWeight:"bold",width:"30%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",paddingTop:"20px",paddingBottom:"20px",cursor:"pointer",backgroundColor: tradeType == "buy"? "green":"rgb(18,22,28)" ,color:"white"}} 
                            onClick={()=>{
                                set_tradeType("buy");
                                }}
                            >Buy</div>
                        <div style={{boxShadow: "10px 10px 10px black",borderRadius:"20px",fontWeight:"bold",width:"30%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",paddingTop:"20px",paddingBottom:"20px",cursor:"pointer",backgroundColor:tradeType == "sell"? "red": "rgb(18,22,28)",color:"white"}} 
                            onClick={()=>{
                                set_tradeType("sell");
                                }}
                            >Sell</div>
                        </div>

                        <div style={{width:"80%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>

                        <div style={{width:"50%",color:"white",marginTop:"20px",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                            <address style={{color:"rgb(200,200,200)",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",color:"orange",fontWeight:"bold"}}><address>Select asset {" "}</address> <FaChevronCircleDown/> </address>
                            <select value={asset} onChange={(e)=>{
                                setAsset(e.target.value);
                            }}  style={{width:"30%",height:"100%",color:"white",borderWidth:"0px",backgroundColor:"rgb(47,47,47)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}>
                                <option selected></option>
                                {
                                    all_assets.map((item)=>(
                                        <>
                                        {
                                            item != "USDT"?
                                            (<option>{item}</option>):
                                            null
                                        }
                                        </>
                                        
                                    ))
                                }
                            </select>
                        </div>

                        <div style={{width:"50%",marginTop:"20px",color:"white",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                            <address style={{color:"rgb(200,200,200)"}}>Base price</address>
                            <input value={base_price} onChange={(e)=>{
                                set_base_price(e.target.value);
                            }} type="number" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(47,47,47)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                        </div>

                        <div style={{width:"50%",color:"white",marginTop:"20px",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                            <address style={{color:"rgb(200,200,200)"}}>Limit price</address>
                            <input value={price} onChange={(e)=>{
                                set_price(e.target.value);
                            }} type="number" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(47,47,47)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                        </div>

                        <div style={{width:"50%",color:"white",marginTop:"20px",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                            <address style={{color:"rgb(200,200,200)"}}>Number of trades</address>
                            <input value={numb_trad} onChange={(e)=>{
                                set_numb_trad(e.target.value);
                            }} type="number" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(47,47,47)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                        </div>

                        <div style={{width:"50%",color:"white",marginTop:"20px",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                            <address style={{color:"rgb(200,200,200)"}}>Quantity</address>
                            <input value={quan} onChange={(e)=>{
                                set_quan(e.target.value);
                            }} type="number" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(47,47,47)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                        </div>

                        <adress style={{color: mess == "Success"? "green": "red",fontWeight:"bold",marginTop:"10px"}}>{mess}</adress>

                        {
                        executing == false?
                        (<div onClick={executeTrade} style={{width:"100%",color:"white",marginTop:"30px",display:"flex",flexDirection:"row",paddingTop:"20px",paddingBottom:"20px",backgroundColor: tradeType == "buy"? "green": "red",borderRadius:"20px",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                            <address>Execute</address>  
                        </div>):
                        (
                        (<div style={{width:"100%",color:"white",marginTop:"30px",display:"flex",flexDirection:"row",paddingTop:"20px",paddingBottom:"20px",backgroundColor:"transparent",borderRadius:"20px",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                            <CircleLoader color={tradeType == "buy"? "green": "red"}/>
                        </div>)
                        )
                        }
                        

                        </div>
                        
                    </div>
                ):
                null
            }
        </div>
    );
}

export default Trading;