import logo from './logo.svg';
import './App.css';
import {Routes,Route} from "react-router-dom";
import Main_screen from "./content/main_screen";
import Login from './content/login';

function App() {
  
  return (
    <Routes>
      <Route path='/login' element={<Login/>}/>
      <Route path='/' element={<Main_screen/>}/>
    </Routes>
  );
}

export default App;
