import {CircleLoader} from "react-spinners"
import {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ()=>{
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [email,set_email] = useState("");
    const [password,set_password] = useState("");
    const [mess,set_mess] = useState("");

    const login = async()=>{
        setLoading(true);
        set_mess("");
        await axios.post("https://chambsexchange.onrender.com/api/auth/signin",{
            email: email.trim(),
            password: password,
        })
        .then((response)=>{
            console.log("data:", response.data);
            setLoading(false);

            if (response.data.message == "Welcome back" && response.data.existingUser.role == "admin") {

                const currentTime = new Date().getTime();
          
                  localStorage.removeItem("startTime");
                  localStorage.setItem("startTime",currentTime.toString());
          
                  localStorage.removeItem("userToken");
                  localStorage.setItem("userToken", response.data.token);
          
                  localStorage.removeItem("userId");
                  localStorage.setItem("userId", response.data.existingUser._id);
          
                  localStorage.removeItem("userName");
                  localStorage.setItem("userName", response.data.existingUser.userName);
          
                  localStorage.removeItem("emailVerified");
                  localStorage.setItem("emailVerified", response.data.existingUser.emailVerified);
          
                  localStorage.removeItem("referralLink");
                  localStorage.setItem("referralLink", response.data.referralLink);
          
                  localStorage.removeItem("uid");
                  localStorage.setItem("uid", response.data.existingUser.uid);
          
                  //"paymentDetailStatus": false,
                  localStorage.removeItem("paymentDetailStatus");
                  localStorage.setItem("paymentDetailStatus", response.data.existingUser.paymentDetailStatus);
          
                  localStorage.removeItem("verified");
                  localStorage.setItem("verified", response.data.existingUser.isVerifyKYC);
                  console.log(response.data.isVerifyKYC);
                  
                  localStorage.removeItem("userCurrency");
                  localStorage.setItem("userCurrency", response.data.existingUser.countryCurrency);
                  localStorage.removeItem("userBankName");
                  localStorage.setItem("userBankName", response.data.existingUser.bankName);
                  localStorage.removeItem("userAccountName");
                  localStorage.setItem("userAccountName", response.data.existingUser.accountName);
                  localStorage.removeItem("userAccountNumber");
                  localStorage.setItem("userAccountNumber", response.data.existingUser.accountNumber);

                  navigate("/");
              }

        }).catch((err)=>{
            console.log("err: ",err)
            setLoading(false);
            set_mess("Login failed. Please recheck credentials and try again.")
        });
    }


    return (
        <div style={{width:"100%",height:"100%",backgroundColor:"rgb(18,22,28)",position:"absolute",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

            <h1 style={{color:"white",fontFamily:"cursive"}}><strong>ADMIN LOGIN</strong></h1>
            <div style={{width:"60%",height:"50%",backgroundColor:"rgb(47,47,47)",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                
                <div style={{width:"50%",color:"white",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                    <address style={{color:"rgb(200,200,200)"}}>Enter email</address>
                    <input value={email} onChange={(e)=>{
                        set_email(e.target.value);
                    }} type="text" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(18,22,28)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                </div>

                <div style={{width:"50%",color:"white",marginTop:"30px",display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                    <address style={{color:"rgb(200,200,200)"}}>Enter password</address>
                    <input value={password} onChange={(e)=>{
                        set_password(e.target.value);
                    }} type="password" style={{width:"90%",height:"100%",color:"white",backgroundColor:"rgb(18,22,28)",borderRadius:"20px",paddingTop:"20px",paddingBottom:"20px",borderWidth:"0px",paddingLeft:"20px",paddingRight:"20px"}}/>
                </div>

                <adress style={{color:"red",fontWeight:"bold",marginTop:"10px"}}>{mess}</adress>

                {
                    loading == false?

                    (<div style={{width:"30%",color:"white",marginTop:"40px",cursor:"pointer",backgroundColor:"rgb(200,200,20)",paddingTop:"20px",paddingBottom:"20px",borderRadius:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={login}>
                        LOGIN 
                    </div>):
                    (
                        <div style={{marginTop:"40px"}}>
                            <CircleLoader color="rgb(200,200,20)"/>
                        </div>
                        
                    )
                }
                

            </div>

        </div>
    );
}

export default Login;