import axios from "axios";
import { useEffect,useState } from "react";
import {CircleLoader} from "react-spinners"
import { FaCopy } from "react-icons/fa";


const Dashboard = (props)=>{

    const [tot,setTot] = useState({});
    const [tot_loading,setTot_loading] = useState(false);


    const userToken = localStorage.getItem("userToken");
    const [bal,setBal] = useState([{balance: 0,bbalance:0,blockchain: "",currency:"",address:""}]);
    const [isLoadingBalance,setIsLoadingBalance] = useState(false);

    useEffect(()=>{
        const loadBalances = async()=>{
          setIsLoadingBalance(true);
          await axios.get("https://chambsexchange.onrender.com/api/trans/get-wallet",{
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }).then((response)=>{
            console.log("qwerty",response.data);
            setIsLoadingBalance(false);
            setBal(response.data);

          }).catch((err)=>{
            console.log("error",err);
            setIsLoadingBalance(false);
          });
        }
        loadBalances();
      },[]);



    useEffect(()=>{
        const fetchAllBalance = async()=>{
            setTot_loading(true);
            await axios.get("https://chambsexchange.onrender.com/api/balance/get-total-balance").then((response)=>{
                console.log("res:",response.data);
                setTot(response.data.totalBalances);
                setTot_loading(false);
            }).catch(async(err)=>{
                console.log(err);
                setTot_loading(false);
                await fetchAllBalance();
            });
        }
        fetchAllBalance();
    },[]);

    return(
    <div style={{width:props.width,height:props.height,opacity:props.opacity,transition:"width 0.2s linear, height 0.2s linear",marginLeft:"20px",backgroundColor:"white"}}>

        
        <h1><strong>DASHBOARD</strong></h1>
        
        
        <h3 style={{marginTop:"30px"}}><strong>{localStorage.getItem("userName")}'s balance</strong></h3>
        <div style={{backgroundColor:"rgb(18,22,28)",color:"white"}}>
        <div style={{width:"100%",display:"grid",gridTemplateColumns:"1fr 1fr 1fr",gap:"10px"}}>
            {
                isLoadingBalance == false?
                bal.map((item)=>(
                <div style={{paddingLeft:"20px",paddingRight:"20px",paddingTop:"20px",paddingBottom:"20px",display:"flex",boxShadow:"10px 10px 10px black",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <address>{item.blockchain}</address>
                    <address><strong>({item.currency})</strong></address>
                    <address><strong>{item.balance}</strong></address>
                    <address><strong>{item.bbalance}</strong></address>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                        <address style={{color:"rgb(180,180,180)"}}>Blockchain</address>
                        <address style={{color:"orange"}}>{item.blockchain}</address>
                    </div>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                        <address style={{color:"rgb(180,180,180)"}}>Asset</address>
                        <address style={{color:"orange"}}>{item.currency}</address>
                    </div>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                        <address style={{color:"rgb(180,180,180)"}}>Balance</address>
                        <address style={{color:"orange"}}>{item.balance}</address>
                    </div>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                        <address style={{color:"rgb(180,180,180)"}}>BBalance</address>
                        <address style={{color:"orange"}}>{item.bbalance}</address>
                    </div>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                        <address onClick={()=>{
                            navigator.clipboard.writeText(`${item.address}`).then(()=>{
                                alert(`Address copied:  ${item.address}`);
                            })
                        }} style={{color:"orange",cursor:"pointer"}}>Copy address <FaCopy color="white"/></address>
                    </div>
                </div>
            )):
            [1,2,3,4,5].map((item)=>(
                <div style={{paddingLeft:"20px",paddingRight:"20px",paddingTop:"20px",paddingBottom:"20px",display:"flex",boxShadow:"10px 10px 10px black",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <address><CircleLoader color="white"/></address>
                </div>
            ))
            }
        </div>
        </div>
        
        <h3 style={{marginTop:"50px"}}><strong>Total user's balance</strong></h3>
        <div style={{width:"100%",display:"grid",gridTemplateColumns:"1fr 1fr 1fr",gap:"10px"}}>
            {
                tot_loading == false?
                Object.entries(tot).map(([key,value])=>(
                <div style={{paddingLeft:"20px",paddingRight:"20px",paddingTop:"20px",paddingBottom:"20px",display:"flex",boxShadow:"10px 10px 10px black",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <address>Total Balance of {key}</address>
                    <address><strong>{value}</strong></address>
                </div>
            )):
            [1,2,3,4,5].map((item)=>(
                <div style={{paddingLeft:"20px",paddingRight:"20px",paddingTop:"20px",paddingBottom:"20px",display:"flex",boxShadow:"10px 10px 10px black",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <address><CircleLoader/></address>
                </div>
            ))
            }
        </div>

    </div>
    );
}

export default Dashboard;