import logo1 from "../assets/logo.png"
import {FaBell, FaSearch} from "react-icons/fa";

const Top_bar = ()=>{
    return (
        <div className="top_bar">
            <div style={{height:"100%",width:"20%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"start"}}>
                <img src={logo1} style={{width:"100%",height:"100%"}} />
            </div>
            <div style={{height:"100%",width:"20%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                <address><strong>Welcome Back, {localStorage.getItem("userName")}</strong></address>
            </div>
            
            <div style={{width:"50%",height:"100%",display:"flex",alignItems:"center",justifyContent:"start"}}>
            <div style={{width:"100%",height:"60%",backgroundColor:"rgb(47,47,47)",overflow:"hidden",borderRadius:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                <input type="text" style={{width:"95%",height:"100%",color:"white",backgroundColor:"transparent",borderWidth:"0px",paddingLeft:"20px"}}/>
                <div style={{width:"5%",height:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"start",backgroundColor:"transparent",borderRadius:"10px",paddingLeft:"20px"}}><FaSearch size={24} color="white"/></div>
            </div>
            </div>
            {/* <select>
                <option selected>John Scot</option>
            </select> */}
            <div style={{width:"10%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                <FaBell size={25} color="white" style={{cursor:"pointer"}}/>
            </div>
        </div>
    );
}

export default Top_bar;