import {useState} from "react";
import {FaBars} from "react-icons/fa";

const Side_bar = (props)=>{
    const [wall, setWall] = useState(false);
    const [dash,setDash] = useState(true);
    const [trad,setTrad] = useState(false);
    const [coins,set_coins] = useState(false);
    const [trans_history,set_trans_history] = useState(false);


    return (
        <div className="side_bar">
            <div style={{marginTop:"100px",top:"0px",paddingLeft:"60px",paddingRight:"60px",borderRadius:"100px",position:"absolute",cursor:"pointer",backgroundColor:"red",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}
                onClick={props.drawer1}
                ><strong><FaBars/></strong></div>
            <div style={{width:"100%"}}>
                
                <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: dash == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                    setDash(true);
                    setWall(false);
                    setTrad(false);
                    set_trans_history(false);

                    props.dash();
                    
                }}>Dashboard</div>
            </div>

            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: wall == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(true);
                setDash(false);
                setTrad(false);
                set_trans_history(false);

                props.wall();
            }}
            >User's wallets</div>

            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: trad == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(false);
                setDash(false);
                setTrad(true);
                set_trans_history(false);

                props.trad();
            }}>Trading</div>

            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: trans_history == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(false);
                setDash(false);
                setTrad(false);
                set_trans_history(true);
            }}>Transaction history</div>
            
            
            
            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: coins == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(false);
                setDash(false);
                setTrad(false);
                set_trans_history(false);
            }}>User Management</div>
            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: coins == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(false);
                setDash(false);
                setTrad(false);
                set_trans_history(false);
            }}>KYC</div>
            
            <div style={{cursor:"pointer",borderRadius:"20px",backgroundColor: coins == true? "rgb(47,47,47)": "transparent",width:"100%",paddingTop:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{
                setWall(false);
                setDash(false);
                setTrad(false);
                set_trans_history(false);
            }}
            >Settings</div>
        </div>
    );
}

export default Side_bar;